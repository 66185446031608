import * as Sentry from "@sentry/remix";
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

// Sentry.init({
//     dsn: "https://863135603b6d92d6b11835220e9d025d@o4508359402848256.ingest.de.sentry.io/4508359414579280",
//     tracesSampleRate: 1,

//     integrations: [Sentry.browserTracingIntegration({
//       useEffect,
//       useLocation,
//       useMatches
//     }), Sentry.replayIntegration({
//         maskAllText: true,
//         blockAllMedia: true
//     })],

//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1
// })

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});
